body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* hover social media */

ul.social-media {
  /* display: flex; */
  /* position: absolute; */
  /* transform: translate(-50%, -50%); */
}

ul.social-media li {
  list-style: none;
}

ul.social-media li a {
  width: 35px;
  height: 35px;
  background-color: #fff;
  text-align: center;
  line-height: 80px;
  font-size: 25px;
  margin: 0 6px;
  display: block;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  border: 3px solid #fff;
  z-index: 1;
}

ul.social-media li a .icon {
  position: relative;
  bottom: 25px;
  color: #262626;
  transition: .5s;
  z-index: 3;
  width: 70%;
}

ul.social-media li a:hover .icon {
  color: #fff;
  transform: rotateY(360deg);
}

ul.social-media li a:before {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 100%;
  background: #f00;
  transition: .5s;
  z-index: 2;
}

ul.social-media li a:hover:before {
  top: 0;
}

ul.social-media li:nth-child(1) a:before{
  background: #3b5999;
}

ul.social-media li:nth-child(2) a:before{
  background: #55acee;
}

ul.social-media li:nth-child(3) a:before {
  background: #dd4b39;
}

ul.social-media li:nth-child(4) a:before {
  background: #ba38d4;
}

ul.social-media li:nth-child(5) a:before {
  background: #36b436;
}

ul.social-media li:nth-child(6) a:before {
  background: #dd4b39;
}
ul.social-media li:nth-child(7) a:before{
  background: #55acee;
}

/* hover pelayanan */
ul.pelayanan{
  display: flex;
  /* position: absolute; */
  /* transform: translate(-50%, -50%); */
}

ul.pelayanan li {
  list-style: none;
}

ul.pelayanan li a {
  width: 50px;
  height: 50px;
  background-color: #fff;
  text-align: center;
  line-height: 80px;
  font-size: 25px;
  margin: 0 6px;
  display: block;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  border: 3px solid #fff;
  z-index: 1;
}

ul.pelayanan li a .icon {
  position: relative;
  bottom: 1.1rem;
  color: #262626;
  transition: .5s;
  z-index: 3;
}

.home-visi:hover .icon {
  color: #fff;
  transform: rotateY(360deg);
}

ul.pelayanan li a:before {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 100%;
  background: #f00;
  transition: .5s;
  z-index: 2;
}

.home-visi:hover ul.pelayanan li a:before {
  top: 0;
}

ul.pelayanan li:nth-child(1) a:before{
  background: #49c939;
}

.lobby-kantor{
  background: url('./images/lobby-kantor.jpeg')
}
footer {
  /* background-image: url('./images/footer-bg.jpg'); */
  background: url('./images/footer-bg.jpg') repeat; 
  background-size: cover;
}

ul.footer-socmed {
  display: flex;
  /* position: absolute; */
  /* transform: translate(-50%, -50%); */
}

ul.footer-socmed li {
  list-style: none;
}

ul.footer-socmed li a {
  width: 30px;
  height: 30px;
  background-color: #fff;
  text-align: center;
  line-height: 80px;
  font-size: 25px;
  margin: 0 6px;
  display: block;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  border: 3px solid #fff;
  z-index: 1;
}

ul.footer-socmed li a .icon {
  position: relative;
  bottom: 1.7rem;
  color: #262626;
  transition: .5s;
  z-index: 3;
  width: 70%;
}

ul.footer-socmed li a:hover .icon {
  color: #fff;
  transform: rotateY(360deg);
}

ul.footer-socmed li a:before {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 100%;
  background: #f00;
  transition: .5s;
  z-index: 2;
}

ul.footer-socmed li a:hover:before {
  top: 0;
}

ul.footer-socmed li:nth-child(1) a:before{
  background: #55acee;
}

ul.footer-socmed li:nth-child(2) a:before{
  background: #3b5999;
}

ul.footer-socmed li:nth-child(3) a:before {
  background: #dd4b39;
}

ul.footer-socmed li:nth-child(4) a:before {
  background: #ba38d4;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@import "~slick-carousel/slick/slick.css"; 
@import "~slick-carousel/slick/slick-theme.css";
